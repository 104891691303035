@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap');

$trans: all .25s ease;

.orange_text {
  color: #f8b300;
}

.orange_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: 700;
  color: #3d3c40 !important;
  text-transform: uppercase;
  cursor: pointer;
  background: #F8B300;
  text-decoration: none !important;
  border: none !important;
  transition: $trans;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);

  &:hover {
    background: lighten(#F8B300, 10%);
  }
}

input {
  padding-left: 30px;
  padding-right: 15px;
  font-weight: 500;
  font-size: 24px;
  color: #3d3c40;
  border: 1px solid transparent;
  background: #E1E1E1;
  outline: none !important;
  transition: $trans;

  &:hover {
    border: 1px solid #39A9DC;
  }

  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #3d3c40;
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #3d3c40;
  }

  &::-ms-input-placeholder { /* Microsoft Edge */
    color: #3d3c40;
  }
}

@media (min-width: 1200px) {

  .container {
    max-width: 1170px;
  }
}

@media (min-width: 1400px) {

  .container {
    max-width: 1370px;
  }
}

@media (min-width: 1600px) {

  .container {
    max-width: 1570px;
  }
}


body {
  width: 100%;
  font-family: 'Roboto', sans-serif;
  overflow-x: hidden;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

a {
  outline: none;
}

//HEADER

header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 100;

  &.scroll {

    .top_header {
      display: none;
    }
  }


}

.top_header {
  padding-top: 24px;
  padding-bottom: 22px;

  .col_left {
    display: flex;
    align-items: center;

    a {
      display: block;
      height: 130px;
      width: 263px;
      margin-right: 82px;
      cursor: pointer;
    }
  }

  .header_slogan {

    h4 {
      margin-bottom: 5px;
      font-size: 30px;
      font-weight: 700;
      color: #3d3c40;
    }

    h5 {
      margin-bottom: 0;
      font-size: 26px;
      font-weight: 700;
      color: #3d3c40;
    }
  }

  .header_contacts {
    max-width: 400px;
    margin-left: auto;
    text-align: right;
  }

  .col_right {

    a.blue_btn {
      display: block;
      max-width: 400px;
      margin-bottom: 16px;
      padding-top: 6px;
      padding-right: 30px;
      padding-bottom: 10px;
      font-size: 24px;
      font-weight: 700;
      line-height: 28px !important;
      color: #fff !important;
      text-decoration: none !important;
      text-align: right;
      background: #39A9DC url("../img/icons/phone_btn_header_ico.png") no-repeat center left 22px;
      transition: $trans;
      box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);

      &:hover {
        background: lighten(#39A9DC, 10%) url("../img/icons/phone_btn_header_ico.png") no-repeat center left 22px;
      }
    }

    a {
      font-size: 28px;
      line-height: 10px;
      font-weight: 900;
      color: #3d3c40;
      text-decoration: none !important;
      transition: $trans;

      &:hover {
        color: #F8B300;
      }
    }

    p {
      margin-bottom: 0;
      font-size: 19px;
      color: #3d3c40;
      font-weight: 700;
      text-transform: uppercase;
    }
  }
}

//NAV

nav {
  background: #143C4E;

  ul {
    display: flex;
    align-items: center;
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
  }

  li {
    width: 12.5%;
    border-right: 1px solid #205C77;
    transform: skew(-20deg);
    transition: $trans;

    &:last-of-type {
      border-right: 1px solid transparent;
    }

    &:hover, &.light {
      background: #39A9DC;
    }
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 72px;
    width: 100%;
    font-size: 18px;
    font-weight: 700;
    color: #fff !important;
    text-decoration: none !important;
    text-align: center;
    transform: skew(20deg);
  }
}

//MAIN SCREEN

.main_screen {
  padding-top: 297px;
  padding-bottom: 32px;
  background: url("../img/main_screen_bg.jpg") no-repeat center;
  background-size: cover;

  h1 {
    font-size: 90px;
    font-weight: 700;
    color: #143c4e;
    text-transform: uppercase;

    .orange_text {
      font-weight: 900;
      font-size: 121px;
    }
  }

  h4 {
    position: relative;
    display: inline-block;
    margin-bottom: 477px;
    padding-left: 11px;
    padding-right: 42px;
    font-weight: 700;
    line-height: 32px;
    color: #fff;
    background-color: #39A9DC;

    &:after {
      position: absolute;
      content: url("../img/icons/main_screen_wave1.png");
      bottom: -81px;
      left: 5px;
    }
  }
}

.main_screen_bottom {

  p {
    font-size: 27px;
    line-height: 32px;
    font-weight: 900;
    color: #143c4e;
  }

  .orange_btn {
    position: relative;
    height: 72px;
    width: 290px;
    margin-left: auto;

    &:before {
      position: absolute;
      content: url("../img/icons/main_screen_wave2.png");
      top: -57px;
    }
  }
}

//POOLS

.pools_1 {
  padding-top: 43px;
  padding-bottom: 41px;
  background: url("../img/pools1_bg.jpg") no-repeat center;
  background-size: cover;
}

.pools_1_top {

  h3 {
    margin-bottom: 12px;
    font-size: 30px;
    font-weight: 700;
    color: #3d3c40;
  }

  h4 {
    display: inline-block;
    margin-bottom: 76px;
    padding-left: 18px;
    padding-right: 18px;
    font-size: 30px;
    font-weight: 700;
    line-height: 58px;
    color: #fff;
    background: #39A9DC;
  }
}

.pools1_item {
  text-align: center;

  h5 {
    margin-bottom: 16px;
    font-size: 36px;
    font-weight: 700;
    color: #3d3c40;
  }

  img {
    margin-bottom: 18px;
  }

  p {
    font-size: 24px;
    line-height: 25px;
    font-weight: 700;
    color: #3d3c40;
  }
}

.row_pools1_items {
  margin-bottom: 208px;
}

.pools1_bottom {

  .row {
    align-items: center;
  }

  h2 {
    margin-bottom: 22px;
    font-size: 37px;
    font-weight: 700;
    color: #143c4e;
  }

  h4 {
    padding-left: 10px;
    padding-right: 42px;
    display: inline-block;
    font-size: 32px;
    line-height: 36px;
    font-weight: 700;
    color: #fff;
    background: #39A9DC;
  }

  a.orange_btn {
    height: 72px;
    width: 292px;
    margin-left: auto;
  }
}

//POOLS 2

.pools_2 {
  padding-top: 122px;
  padding-bottom: 15px;
  background-color: #0C232E;

  .col_left {

    .pools2_item {

      &:nth-of-type(1) {

        p:before {
          background: url("../img/icons/pools2_1_ico.png") no-repeat center top 5px;
        }
      }

      &:nth-of-type(2) {

        p:before {
          background: url("../img/icons/pools2_2_ico.png") no-repeat center top 5px;
        }
      }
    }
  }

  .col_right {

    .pools2_item_wrap {
      max-width: 657px;
      margin-left: auto;

      .pools2_item:nth-of-type(1) {
        margin-bottom: 47px;
      }
    }

    .pools2_item {

      &:nth-of-type(1) {

        p:before {
          background: url("../img/icons/pools2_3_ico.png") no-repeat center top 14px;
        }
      }

      &:nth-of-type(2) {

        p:before {
          background: url("../img/icons/pools2_4_ico.png") no-repeat center top 8px;
        }
      }
    }
  }

  form {
    margin-top: 54px;
  }

  .top_form {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;

    input {
      height: 72px;
      width: 405px;
    }

    button {
      width: 280px;
    }
  }

  .bottom_form {

    .ui-button {
      margin: 0;
      border-radius: 0;
      padding: 0;
      font-size: 18px;
      font-weight: 700;
      color: #fff !important;
      background-color: transparent !important;
      box-shadow: none !important;
      border: 0 !important;
    }

    .ui-button .ui-icon {
      height: 26px;
      width: 26px;
      margin-right: 22px;
      border-radius: 0;
      border: 0 !important;
      background-color: #E1E1E1 !important;
      background-image: none;
    }

    .ui-state-active .ui-icon, .ui-button:active .ui-icon {
      background: url("../img/icons/checkmark.png") no-repeat center;
    }

    .row div {
      display: flex;
      align-items: center;

      &:nth-of-type(2) {
        justify-content: flex-end;
      }
    }
  }
}

.pools2_item {
  padding-left: 132px;
  margin-bottom: 20px;

  img {
    margin-bottom: 21px;
  }

  h4 {
    margin-bottom: 18px;
    font-size: 30px;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
  }

  p {
    position: relative;
    font-size: 18px;
    line-height: 25px;
    font-weight: 700;
    color: #fff;

    &:before {
      position: absolute;
      content: '';
      display: block;
      height: 95px;
      width: 95px;
      left: -132px;
      border: 3px solid #fff;
      border-radius: 50%;
    }
  }
}

.pools2_item_wrap {

  img {
    margin-bottom: 19px;
  }
}


//POOLS 3

.pools_3 {
  padding-top: 122px;
  padding-bottom: 15px;
  background: url("../img/pools3_bg.jpg") no-repeat center;
  background-size: cover;

  .col_left {

    .pools3_item {

      &:nth-of-type(1) {

        p:before {
          background: url("../img/icons/pools3_1_ico.png") no-repeat center top 11px;
        }
      }

      &:nth-of-type(2) {

        p:before {
          background: url("../img/icons/pools3_2_ico.png") no-repeat center top 10px;
        }
      }
    }
  }

  .col_right {

    .pools3_item_wrap {
      max-width: 657px;
      margin-left: auto;

      .pools3_item:nth-of-type(1) {
        margin-bottom: 71px;
      }
    }

    .pools3_item {

      &:nth-of-type(1) {

        p:before {
          background: url("../img/icons/pools3_3_ico.png") no-repeat center top 14px;
        }
      }

      &:nth-of-type(2) {

        p:before {
          background: url("../img/icons/pools3_4_ico.png") no-repeat center top 17px;
        }
      }
    }
  }

  form {
    margin-top: 54px;
  }

  .top_form {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;

    input {
      height: 72px;
      width: 405px;
    }

    button {
      width: 280px;
    }
  }

  .bottom_form {

    .ui-button {
      margin: 0;
      border-radius: 0;
      padding: 0;
      font-size: 18px;
      font-weight: 700;
      color: #3d3c40 !important;
      background-color: transparent !important;
      box-shadow: none !important;
      border: 0 !important;
    }

    .ui-button .ui-icon {
      height: 26px;
      width: 26px;
      margin-right: 22px;
      border-radius: 0;
      border: 0 !important;
      background-color: #E1E1E1 !important;
      background-image: none;
    }

    .ui-state-active .ui-icon, .ui-button:active .ui-icon {
      background: url("../img/icons/checkmark.png") no-repeat center;
    }

    .row div {
      display: flex;
      align-items: center;

      &:nth-of-type(2) {
        justify-content: flex-end;
      }
    }
  }
}

.pools3_item {
  padding-left: 132px;
  margin-bottom: 35px;

  img {
    margin-bottom: 21px;
  }

  h4 {
    margin-bottom: 18px;
    font-size: 30px;
    font-weight: 700;
    color: #3d3c40;
    text-transform: uppercase;
  }

  p {
    position: relative;
    font-size: 18px;
    line-height: 25px;
    font-weight: 700;
    color: #3d3c40;

    &:before {
      position: absolute;
      content: '';
      display: block;
      height: 95px;
      width: 95px;
      left: -132px;
      border: 3px solid #3d3c40;
      border-radius: 50%;
    }
  }
}

.pools3_item_wrap {

  img {
    margin-bottom: 19px;
  }
}

//POOLS 4

.pools_4 {
  padding-top: 122px;
  padding-bottom: 15px;
  background-color: #0C232E;

  .col_right {

    .pools4_item_wrap {
      max-width: 657px;
      margin-left: auto;

      .pools4_item:nth-of-type(1) {
        margin-bottom: 47px;
      }
    }
  }

  form {
    margin-top: 54px;
  }

  .top_form {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;

    input {
      height: 72px;
      width: 405px;
    }

    button {
      width: 280px;
    }
  }

  .bottom_form {

    .ui-button {
      margin: 0;
      border-radius: 0;
      padding: 0;
      font-size: 18px;
      font-weight: 700;
      color: #fff !important;
      background-color: transparent !important;
      box-shadow: none !important;
      border: 0 !important;
    }

    .ui-button .ui-icon {
      height: 26px;
      width: 26px;
      margin-right: 22px;
      border-radius: 0;
      border: 0 !important;
      background-color: #E1E1E1 !important;
      background-image: none;
    }

    .ui-state-active .ui-icon, .ui-button:active .ui-icon {
      background: url("../img/icons/checkmark.png") no-repeat center;
    }

    .row div {
      display: flex;
      align-items: center;

      &:nth-of-type(2) {
        justify-content: flex-end;
      }
    }
  }
}

.pools4_item {
  margin-bottom: 20px;

  img {
    margin-bottom: 21px;
  }

  h4 {
    margin-bottom: 18px;
    font-size: 30px;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
  }

  p {
    margin-bottom: 3px;
    font-size: 24px;
    line-height: 25px;
    color: #fff;
  }

  h5 {
    margin-bottom: 4px;
    font-size: 24px;
    line-height: 25px;
    font-weight: 900;
    color: #fff;
  }

  ul {
    margin-bottom: 4px;
    padding-left: 0;

    li {
      font-size: 24px;
      line-height: 25px;
      color: #fff;
    }
  }
}

.pools4_item_wrap {

  img {
    margin-bottom: 19px;
  }
}

//POOLS 5

.pools_5 {
  padding-top: 105px;
  padding-bottom: 15px;
  background: url("../img/pools5_bg.jpg") no-repeat center;
  background-size: cover;

  h2 {
    font-size: 48px;
    font-weight: 700;
    color: #143c4e;
  }

  .col_left {

    .img_wrap {
      height: 84px;
      margin-bottom: 6px;
    }

    img {
      display: block;
      margin-left: 133px;
    }
  }

  .col_right {
    padding-top: 37px;
  }

  h4 {
    margin-bottom: 12px;
    font-size: 24px;
    font-weight: 900;
    line-height: 25px;
    color: #143c4e;
  }

  p {
    margin-bottom: 0;
    font-size: 18px;
    line-height: 25px;
    font-weight: 700;
    color: #143c4e;
  }

  ul {
    padding-left: 0;
  }

  li {
    font-size: 18px;
    line-height: 25px;
    font-weight: 700;
    color: #143c4e;
  }


  form {
    margin-top: 54px;
  }

  .top_form {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;

    input {
      height: 72px;
      width: 405px;
    }

    button {
      width: 280px;
    }
  }

  .bottom_form {

    .ui-button {
      margin: 0;
      border-radius: 0;
      padding: 0;
      font-size: 18px;
      font-weight: 700;
      color: #3d3c40 !important;
      background-color: transparent !important;
      box-shadow: none !important;
      border: 0 !important;
    }

    .ui-button .ui-icon {
      height: 26px;
      width: 26px;
      margin-right: 22px;
      border-radius: 0;
      border: 0 !important;
      background-color: #E1E1E1 !important;
      background-image: none;
    }

    .ui-state-active .ui-icon, .ui-button:active .ui-icon {
      background: url("../img/icons/checkmark.png") no-repeat center;
    }

    .row div {
      display: flex;
      align-items: center;

      &:nth-of-type(2) {
        justify-content: flex-end;
      }
    }
  }
}


//EQUIPMENT

.equipment {
  padding-top: 128px;
  padding-bottom: 15px;
  background-color: #0C232E;

  h2 {
    margin-bottom: 4px;
    font-size: 48px;
    font-weight: 700;
    color: #fff;
    text-align: center;
  }

  h3 {
    margin-bottom: 105px;
    font-size: 40px;
    color: #fff;
    text-align: center;
  }

  .top_form {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;

    input {
      height: 72px;
      width: 405px;
    }

    button {
      width: 280px;
    }
  }

  .bottom_form {

    .ui-button {
      margin: 0;
      border-radius: 0;
      padding: 0;
      font-size: 18px;
      font-weight: 700;
      color: #fff !important;
      background-color: transparent !important;
      box-shadow: none !important;
      border: 0 !important;
    }

    .ui-button .ui-icon {
      height: 26px;
      width: 26px;
      margin-right: 22px;
      border-radius: 0;
      border: 0 !important;
      background-color: #E1E1E1 !important;
      background-image: none;
    }

    .ui-state-active .ui-icon, .ui-button:active .ui-icon {
      background: url("../img/icons/checkmark.png") no-repeat center;
    }

    .row div {
      display: flex;
      align-items: center;

      &:nth-of-type(2) {
        justify-content: flex-end;
      }
    }
  }
}

.col_equipment {
  margin-bottom: 86px;

  &:nth-of-type(1) {

    h4:before {
      content: url("../img/icons/pools6_1_ico.png");
    }
  }

  &:nth-of-type(2) {

    h4:before {
      content: url("../img/icons/pools6_2_ico.png");
    }
  }

  &:nth-of-type(3) {

    h4:before {
      content: url("../img/icons/pools6_3_ico.png");
    }
  }

  &:nth-of-type(4) {

    h4:before {
      content: url("../img/icons/pools6_4_ico.png");
    }
  }

  &:nth-of-type(5) {

    h4:before {
      content: url("../img/icons/pools6_5_ico.png");
    }
  }

  &:nth-of-type(6) {

    h4:before {
      content: url("../img/icons/pools6_6_ico.png");
    }
  }

  h4 {
    position: relative;
    height: 86px;
    margin-bottom: 18px;
    padding-left: 158px;
    font-size: 36px;
    font-weight: 700;
    color: #fff;

    &:before {
      position: absolute;
      top: -44px;
      left: 0;
    }
  }

  p {
    font-size: 24px;
    line-height: 25px;
    font-weight: 700;
    color: #fff;
  }
}

//COATING

.coating {
  padding-top: 114px;
  padding-bottom: 15px;
  background: url("../img/coating_bg.jpg") no-repeat center;
  background-size: cover;

  form {
    margin-top: 54px;
  }

  .top_form {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;

    input {
      height: 72px;
      width: 405px;
    }

    button {
      width: 280px;
    }
  }

  .bottom_form {

    .ui-button {
      margin: 0;
      border-radius: 0;
      padding: 0;
      font-size: 18px;
      font-weight: 700;
      color: #3d3c40 !important;
      background-color: transparent !important;
      box-shadow: none !important;
      border: 0 !important;
    }

    .ui-button .ui-icon {
      height: 26px;
      width: 26px;
      margin-right: 22px;
      border-radius: 0;
      border: 0 !important;
      background-color: #E1E1E1 !important;
      background-image: none;
    }

    .ui-state-active .ui-icon, .ui-button:active .ui-icon {
      background: url("../img/icons/checkmark.png") no-repeat center;
    }

    .row div {
      display: flex;
      align-items: center;

      &:nth-of-type(2) {
        justify-content: flex-end;
      }
    }
  }
}

.coating_item {
  max-width: 492px;

  img {
    margin-bottom: 13px;
  }

  h3 {
    margin-bottom: 38px;
    font-size: 36px;
    font-weight: 700;
    color: #143c4e;
    text-align: center;
  }

  ul {
    padding-left: 0;
    margin-bottom: 0;
  }

  li {
    margin-bottom: 28px;
    font-size: 22px;
    line-height: 25px;
    font-weight: 700;
    color: #143c4e;
  }
}

//CALCULATE FORM

.calculate_form {
  padding-top: 120px;
  padding-bottom: 20px;
  background: #0C232E;

  h2 {
    font-size: 48px;
    font-weight: 700;
    color: #fff;
    text-align: center;
  }

  h4 {
    margin-bottom: 87px;
    font-size: 40px;
    color: #fff;
    text-align: center;
    
    span {
      position: relative;
      
      &:before {
        position: absolute;
        content: url("../img/icons/contact_form1.png");
        left: -140px;
        top: 0;
      }
    }
  }

  .top_form {
    display: flex;
    justify-content: space-between;
    margin-top: 118px;
    margin-bottom: 40px;

    input {
      height: 72px;
      width: 405px;
    }

    button {
      width: 280px;
    }
  }

  .bottom_form {

    .ui-button {
      margin: 0;
      border-radius: 0;
      padding: 0;
      font-size: 18px;
      font-weight: 700;
      color: #fff !important;
      background-color: transparent !important;
      box-shadow: none !important;
      border: 0 !important;
    }

    .ui-button .ui-icon {
      height: 26px;
      width: 26px;
      margin-right: 22px;
      border-radius: 0;
      border: 0 !important;
      background-color: #E1E1E1 !important;
      background-image: none;
    }

    .ui-state-active .ui-icon, .ui-button:active .ui-icon {
      background: url("../img/icons/checkmark.png") no-repeat center;
    }

    .row div {
      display: flex;
      align-items: center;

      &:nth-of-type(2) {
        justify-content: flex-end;
      }
    }
  }

  .col_left {

    .input_wrap {
      display: flex;
      align-items: center;
      margin-bottom: 33px;

      span {
        max-width: 216px;
        font-size: 24px;
        line-height: 35px;
        color: #fff;
      }
    }

    input {
      height: 72px;
      width: 316px;
      margin-right: 30px;
      padding-left: 55px;
    }

    .checkbox_wrap {
      margin-top: 69px;
      margin-bottom: 79px;

      .ui-button {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0;
        border-radius: 0;
        max-width: 570px;
        width: 100%;
        padding: 0;
        font-size: 24px;
        line-height: 25px;
        font-weight: 700;
        color: #fff !important;
        background-color: transparent !important;
        box-shadow: none !important;
        border: 0 !important;
        text-align: right;
      }

      .ui-button .ui-icon {
        height: 40px;
        width: 40px;
        margin-right: 22px;
        border-radius: 0;
        border: 0 !important;
        background-color: #E1E1E1 !important;
        background-image: none;
      }

      .ui-state-active .ui-icon, .ui-button:active .ui-icon {
        background: url("../img/icons/checkmark.png") no-repeat center;
      }
    }
  }

  .col_center {

    .checkbox_wrap {
      margin-bottom: 48px;

      &:last-of-type {
        margin-bottom: 0;
      }

      .ui-button {
        display: flex;
        align-items: center;
        margin: 0;
        border-radius: 0;
        max-width: 570px;
        width: 100%;
        padding: 0;
        font-size: 24px;
        line-height: 25px;
        font-weight: 700;
        color: #fff !important;
        background-color: transparent !important;
        box-shadow: none !important;
        border: 0 !important;
        text-align: right;
      }

      .ui-button .ui-icon {
        height: 40px;
        width: 40px;
        margin-right: 35px;
        border-radius: 0;
        border: 0 !important;
        background-color: #E1E1E1 !important;
        background-image: none;
      }

      .ui-state-active .ui-icon, .ui-button:active .ui-icon {
        background: url("../img/icons/checkmark.png") no-repeat center;
      }
    }
  }

  .col_right {

    .checkbox_wrap {
      margin-bottom: 48px;

      .ui-button {
        display: flex;
        align-items: center;
        margin: 0;
        border-radius: 0;
        max-width: 570px;
        width: 100%;
        padding: 0;
        font-size: 24px;
        line-height: 25px;
        font-weight: 700;
        color: #fff !important;
        background-color: transparent !important;
        box-shadow: none !important;
        border: 0 !important;
        text-align: right;
      }

      .ui-button .ui-icon {
        height: 40px;
        width: 40px;
        margin-right: 35px;
        border-radius: 0;
        border: 0 !important;
        background-color: #E1E1E1 !important;
        background-image: none;
      }

      .ui-state-active .ui-icon, .ui-button:active .ui-icon {
        background: url("../img/icons/checkmark.png") no-repeat center;
      }
    }

    textarea {
      padding-top: 23px;
      padding-left: 35px;
      padding-right: 10px;
      padding-bottom: 20px;
      font-size: 24px;
      color: #0c232e;
      height: 200px;
      width: 100%;
      border: none !important;
      outline: none !important;
    }
  }
}

//WARRANTY

.warranty {
  padding-top: 33px;
  background: url("../img/warranty_bg.jpg") no-repeat center;
  background-size: cover;

  form {
    margin-top: 54px;
  }

  .top_form {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;

    input {
      height: 72px;
      width: 405px;
    }

    button {
      width: 280px;
    }
  }

  .bottom_form {

    .ui-button {
      margin: 0;
      border-radius: 0;
      padding: 0;
      font-size: 18px;
      font-weight: 700;
      color: #3d3c40 !important;
      background-color: transparent !important;
      box-shadow: none !important;
      border: 0 !important;
    }

    .ui-button .ui-icon {
      height: 26px;
      width: 26px;
      margin-right: 22px;
      border-radius: 0;
      border: 0 !important;
      background-color: #E1E1E1 !important;
      background-image: none;
    }

    .ui-state-active .ui-icon, .ui-button:active .ui-icon {
      background: url("../img/icons/checkmark.png") no-repeat center;
    }

    .row div {
      display: flex;
      align-items: center;

      &:nth-of-type(2) {
        justify-content: flex-end;
      }
    }
  }
}

.warranty_item {
  margin-bottom: 26px;
  text-align: center;

  img {
    margin-bottom: 8px;
  }

  p {
    font-size: 24px;
    line-height: 25px;
    font-weight: 900;
    color: #143c4e;

    span {
      display: block;
      margin-top: 7px;
      text-transform: uppercase;
    }
  }
}

.col_warranty {
  padding-left: 120px;
}

.warranty_bottom {
  padding-top: 11px;
  border-top: 5px solid #859190;

  h3 {
    margin-bottom: 29px;
    font-size: 36px;
    font-weight: 700;
    color: #143c4e;
    text-align: center;
  }

  ul {
    padding-left: 0;
    margin-bottom: 0;
  }

  li {
    margin-bottom: 44px;
    font-size: 24px;
    line-height: 25px;
    font-weight: 900;
    color: #143c4e;
  }

  h4 {
    font-size: 24px;
    line-height: 25px;
    font-weight: 900;
    color: #143c4e;
  }

  p {
    font-weight: 700;
    font-size: 18px;
  }
}

//WORKS

.works {
  padding-bottom: 33px;
  padding-top: 124px;
  background: #0C232E;

  h2 {
    margin-bottom: 36px;
    font-size: 40px;
    color: #fff;
  }

  img {
    margin-bottom: 30px;
  }

  .col_right {
    padding-left: 50px;
  }

  p {
    font-weight: 700;
    font-size: 25px;
    line-height: 32px;
    color: #fff;
  }

  .orange_btn {
    margin-top: 64px;
    margin-left: auto;
    height: 72px;
    width: 292px;
  }
}

//CONTACTS

.contacts {
  padding-top: 125px;
  padding-bottom: 38px;
  background: url("../img/contacts_bg.jpg") no-repeat center;
  background-size: cover;

  h2 {
    margin-bottom: 86px;
    font-size: 36px;
    font-weight: 700;
    text-transform: uppercase;
    color: #143c4e;
    text-align: center;
  }

  .top_form {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;

    input {
      height: 72px;
      width: 405px;
    }

    button {
      width: 280px;
    }
  }

  .bottom_form {

    .ui-button {
      margin: 0;
      border-radius: 0;
      padding: 0;
      font-size: 18px;
      font-weight: 700;
      color: #143c4e !important;
      background-color: transparent !important;
      box-shadow: none !important;
      border: 0 !important;
    }

    .ui-button .ui-icon {
      height: 26px;
      width: 26px;
      margin-right: 22px;
      border-radius: 0;
      border: 0 !important;
      background-color: #E1E1E1 !important;
      background-image: none;
    }

    .ui-state-active .ui-icon, .ui-button:active .ui-icon {
      background: url("../img/icons/checkmark.png") no-repeat center;
    }

    .row div {
      display: flex;
      align-items: center;

      &:nth-of-type(2) {
        justify-content: flex-end;
      }
    }
  }
}

.top_contacts_row {
  margin-bottom: 141px;

  .col_left {

    .contact_item {
      position: relative;
      padding-left: 100px;

      &:nth-of-type(1) {
        margin-bottom: 97px;
      }

      &:nth-of-type(1):before {
        position: absolute;
        content: url("../img/icons/contacts1.png");
        left: 0;
        top: 16px;
      }

      &:nth-of-type(2):before {
        position: absolute;
        content: url("../img/icons/contacts2.png");
        left: 0;
        top: -2px;
      }

      a {
        display: block;
        font-size: 29px;
        line-height: 35px;
        font-weight: 900;
        color: #143c4e;
        text-decoration: none !important;
        transition: $trans;

        &:hover {
          color: #F8B300;
        }
      }

      p {
        display: block;
        margin-bottom: 0;
        font-size: 24px;
        font-weight: 900;
        color: #143c4e;
      }
    }
  }
}

.bottom_contacts_row {
  margin-bottom: 93px;

  .contact_item {
    position: relative;
    padding-left: 100px;

    &:nth-of-type(1) {
      margin-bottom: 52px;
    }

    &:nth-of-type(1):before {
       position: absolute;
       content: url("../img/icons/contacts3.png");
       left: 0;
       top: -2px;
     }

    &:nth-of-type(2):before {
      position: absolute;
      content: url("../img/icons/contacts4.png");
      left: 0;
      top: -18px;
    }

    a {
      display: block;
      font-size: 29px;
      line-height: 35px;
      font-weight: 900;
      color: #143c4e;
      text-decoration: none !important;
      transition: $trans;

      &:hover {
        color: #F8B300;
      }
    }

    p {
      display: block;
      margin-bottom: 0;
      font-size: 24px;
      font-weight: 900;
      color: #143c4e;
    }
  }

  .address {
    position: relative;
    margin-bottom: 58px;
    padding-left: 72px;
    font-size: 24px;
    font-weight: 900;
    color: #143c4e;

    &:before {
      position: absolute;
      content: url("../img/icons/contacts5.png");
      left: 0;
      top: -18px;
    }
  }

  .social {
    display: flex;
    align-items: center;

    a {
      margin-right: 28px;
      transition: $trans;

      &:hover {
        opacity: .8;
      }
    }

    span {
      font-weight: 900;
      font-size: 24px;
      color: #143c4e;
    }
  }
}

//POPUP

.mfp-content {
  max-width: 500px;
  padding-top: 30px;
  padding-bottom: 50px;
  padding-left: 50px;
  padding-right: 50px;
  background: #143C4E;
  text-align: center;
  border-radius: 10px;

  h2 {
    margin-bottom: 30px;
    font-weight: 700;
    font-size: 32px;
    color: #fff;
  }

  input {
    height: 72px;
    width: 100%;
    margin-bottom: 20px;
  }

  .orange_btn {
    height: 72px;
    width: 100%;
  }
}

.mfp-close-btn-in .mfp-close {
  color: #fff;
}

                                    //THANKS PAGE

.thanks_page {
  padding-top: 400px;
  padding-bottom: 200px;
}
.thanks_center {
  padding-top: 50px;
  padding-bottom: 50px;
  left: 30px;
  padding-right: 30px;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  background: #143C4E;
  border-radius: 10px;
  text-align: center;

  h5 {
    margin-bottom: 30px;
    font-weight: 700;
    font-size: 84px;
    color: #fff;
  }

  h6 {
    font-weight: 700;
    font-size: 26px;
    color: #fff;

    a {
      color: #fff;
      text-decoration: none;
      border-bottom: 2px solid #fff;
      transition: $trans;

      &:hover {
        color: #F8B300;
        border-bottom: 2px solid transparent;
      }
    }
  }
}